(function ($) {
    $(document).ready(function () {
        /* custom js */
        $(function () {
            var Accordion = function (el, multiple) {
                this.el = el || {};
                // more then one submenu open?
                this.multiple = multiple || false;
                var dropdownlink = this.el.find('.dropdownlink');
                dropdownlink.on('click',
                    { el: this.el, multiple: this.multiple },
                    this.dropdown);
            };
            Accordion.prototype.dropdown = function (e) {
                var $el = e.data.el,
                    $this = $(this),
                    //this is the ul.submenuItems
                    $next = $this.next();
                $next.slideToggle();
                $this.parent().toggleClass('open');
                if (!e.data.multiple) {
                    //show only one menu at the same time
                    $el.find('.submenuItems').not($next).slideUp().parent().removeClass('open');
                }
            }
            var accordion = new Accordion($('.accordion-menu'), false);
        })

        // modal window
        $(document).ready(function () {
            function setCookie(cname, cvalue, exdays) {
                var d = new Date();
                d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
                var expires = "expires=" + d.toGMTString();
                document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
            }

            // Показываем попап
            function showPopap() {
                $("#popupregister_background").addClass("background-popup");
                $("#popupregister_wrapper").addClass("show-popup-container");
                $("#popupregister_wrapper > #popupregister").addClass("show-popup");
                $("#popupregister_wrapper > #popupregister").attr("aria-hidden", false);
                $("#popupregister > .head > img.close").click(function () {
                    closePopup();
                })
                $("#popupregister").before('<div class="popup-image"></div>');
                $("#popupregister > .but > #button_register").after('<span class="remind-in-a-month">напомнить мне об этом через месяц</span>');
                $("#pur_phone").attr("autocomplete", "off");
                $(".remind-in-a-month").click(function () {
                    closePopupAndRemind();
                })
                $(".remind-in-a-month").click(function () {
                    closePopupAndRemind();
                })
                $("#button_register").click(function () {
                    closePopupForever();
                })
            }

            // Скрыть попап
            function closePopupForever() {
                setCookie("showPopap", false, 365);
            }

            function closePopup() {
                $("#popupregister_background").removeClass("background-popup");
                $("#popupregister_wrapper").removeClass("show-popup-container");
                $("#popupregister_wrapper > #popupregister").removeClass("show-popup");
                $("#popupregister_wrapper > #popupregister").attr("aria-hidden", true);
                setCookie("showPopap", false, 1);
            }

            function closePopupAndRemind() {
                $("#popupregister_background").removeClass("background-popup");
                $("#popupregister_wrapper").removeClass("show-popup-container");
                $("#popupregister_wrapper > #popupregister").removeClass("show-popup");
                $("#popupregister_wrapper > #popupregister").attr("aria-hidden", true);
                setCookie("showPopap", false, 30);
            }

            function readCookie(name) {
                var name_cook = name + "=";
                var spl = document.cookie.split(";");
                for (var i = 0; i < spl.length; i++) {
                    var c = spl[i];
                    while (c.charAt(0) == " ") {
                        c = c.substring(1, c.length);
                    }
                    if (c.indexOf(name_cook) == 0) {
                        return c.substring(name_cook.length, c.length);
                    }
                }
                return null;
            }

            var value_cookie = readCookie("showPopap");

            if ((value_cookie === null) || (value_cookie === "true")) {
                setCookie("showPopap", true, 1);
                // Показываем попап
                setTimeout(showPopap, 5000);
            }
        });

        // big banner
        $('.big-banner').slick({
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 300,
            autoplay: true,
            autoplaySpeed: 4000,
            dots: true,
            arrows: false,
            cssEase: 'linear',
            lazyLoad: 'ondemand',
            fade: true,
            responsive: [{
                breakpoint: 768,
                settings: {
                    dots: false,
                    centerMode: true
                }
            }],
        });

        // slider in our partners block
        $('.our-partners__box').slick({
            infinite: true,
            slidesToShow: 6,
            slidesToScroll: 1,
            speed: 400,
            autoplay: true,
            autoplaySpeed: 2000,
            dots: false,
            arrows: true,
            cssEase: 'linear',
            lazyLoad: 'ondemand',
            responsive: [{
                breakpoint: 768,
                settings: {
                    arrows: false,
                    slidesToShow: 2,
                    arrows: true,
                }
            }],
        });

        // слайдер торговых марок
        $('.our-trademarks__slider').slick({
            // infinite: true,
            slidesToShow: 6,
            slidesToScroll: 1,
            speed: 400,
            autoplay: true,
            autoplaySpeed: 3000,
            dots: false,
            arrows: true,
            cssEase: 'linear',
            lazyLoad: 'ondemand',
            responsive: [{
                breakpoint: 768,
                settings: {
                    arrows: false,
                    slidesToShow: 2,
                    arrows: true,
                }
            }],
        });

        $('.megamenu-burger').on('click', function() {
            $('#megamenu-menu').addClass('open-menu');
            $('<button class="close-menu"></button>').insertBefore('.nav.navbar-nav');
            $('.close-menu').on('click', function(){
                $('#megamenu-menu').removeClass('open-menu');
                $('.close-menu').remove();
            });
        });

        // якорные ссылки в верхнем меню
        $(".navbar-collapse .nav .dropdown").on("click","a", function (event) {
            const id  = $(this).attr('href');
            const top = $(id).offset().top;
            $('body,html').animate({scrollTop: top}, 1500);
        });

        // открытие формы обратной связи
        $("#form-call-back").on('click', () => {
            $('#form-builder-61').addClass('show');
        });

        // закрытие формы обратной связи с помощью кастомной кнопки
        $('#form-builder-61 .btn-primary').after('<button id="close-form-call-back" class="btn btn-primary" style="float: right; font-size: 25px;">&times</button>');
        $('#close-form-call-back').on('click', () => {
            $('#form-builder-61').removeClass('show');
        });

        // страница контакты -> показать телефоны
        $('.get-phones').on('click', function() {
            $(this).prev().addClass('show-phones');
            $(this).css('display', 'none');
            $(this).next().css('display', 'block');
        });

        // страница контакты -> скрыть телефоны
        $('.hidden-phones').on('click', function() {
            $(this).prev().prev().removeClass('show-phones');
            $(this).css('display', 'none');
            $(this).prev().css('display', 'block');
        });
    });
})(jQuery);